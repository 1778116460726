import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  Popover,
  Popper,
  Radio,
  RadioGroup,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import { CustomButton } from "components/CustomButton";
import { Icon } from "components/Icon";
import { useContext, useEffect, useState } from "react";

const SORT = ["Most Recent", "Price", "Due date"];
const ORDERED = ["Ascending", "Descending"];

export const Sort = ({ setFilter, filter }: any): JSX.Element => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sort, setSort] = useState("");
  const [ordered, setOrdered] = useState("Ascending");

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOrdered(filter.sort_direction);
    if (filter.sort_by === "budget") {
      setSort("Price");
    } else {
      setSort(filter.sort_by);
    }
  };

  const handleSubmit = () => {
    if (sort === "Price") {
      setFilter("sort_by", "budget");
    } else setFilter("sort_by", sort);

    if (ordered === "Descending") {
      setFilter("sort_direction", "Descending");
    } else {
      setFilter("sort_direction", "Ascending");
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    if (filter.sort_by === "budget") {
      setSort("Price");
    } else setSort(filter.sort_by);
  }, [filter]);

  return (
    <>
      <IconButton
        // disabled={disabled}
        sx={{
          height: {
            md: "35px",
          },
          display: { md: "flex", xs: "none" },
          alignItems: "center",
          gap: "4px",
          p: "7.5px 8px 7.5px 8px !important",
          bgcolor: palette.neutral[50],
          color: palette.neutral[900],
          borderRadius: "8px",
          transition: "color 0.3s , background-color 0.3s",
          "&:hover": {
            bgcolor: palette.neutral[100],
            transition: "color 0.3s , background-color 0.3s",
          },
          "&.Mui-focusVisible": {
            outline: `1px solid ${palette.neutral[900]}`,
          },
          ":disabled": {
            color: palette.neutral[400],
            bgcolor: palette.neutral[100],
          },
        }}
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <Icon
          iconSet="Sort"
          sx={{ fontSize: "19px", color: palette.neutral[900] }}
        />
        <Typography variant="body2" sx={{ color: palette.neutral[900] }}>
          {Boolean(filter.sort_by) === false
            ? "Sort"
            : filter.sort_by && filter.sort_by === "budget"
            ? "Price"
            : `${filter.sort_by}`
            ? `${filter.sort_by}`
            : ""}
        </Typography>
        <Icon
          iconSet="Arrow2-Down"
          sx={{ fontSize: "19px", mt: 0.4, color: palette.neutral[900] }}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            borderRadius: "16px",
            boxShadow: "0px 2px 4px 0px #00000040",
            mt: { md: 2, xs: 4 },
            width: { xs: "100%", md: "auto" },
            display: { md: "unset", xs: "none" },
          },
        }}
      >
        <Box sx={{ pt: 1, px: 3, width: { md: "360px", xs: "100%" }, mt: 2 }}>
          <Typography
            variant="label"
            sx={{
              color: palette.neutral[500],
            }}
          >
            SORT BY
          </Typography>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <RadioGroup>
              {SORT.map((item, index) => {
                return (
                  <Box key={index}>
                    <FormControlLabel
                      value={item}
                      checked={item === sort}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row-reverse",
                        width: "100%",
                        m: "0px !important",
                        py: "11px",
                      }}
                      control={
                        <Radio
                          sx={{
                            p: "0px !important",
                            color: palette.neutral[400],
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: palette.neutral[700],
                          }}
                        >
                          {item}
                        </Typography>
                      }
                      onClick={(e) => {
                        setSort(
                          (
                            e.currentTarget.children[0]
                              .children[0] as HTMLInputElement
                          ).value
                        );
                      }}
                    />
                    {index + 1 === SORT.length ? null : (
                      <Divider
                        sx={{
                          display: index === 2 ? "none" : "flex",
                          borderColor: palette.neutral[100],
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider
          sx={{
            height: 8,
            backgroundColor: palette.neutral[50],
            borderBottom: "unset",
            borderRadius: "4px",
            mx: "16px",
            display: sort === "Most Recent" ? "none" : "",
          }}
        />
        <Box
          sx={{
            px: 3,
            width: { md: "360px", xs: "100%" },
            mt: 2,
            display: sort === "Most Recent" ? "none" : "",
          }}
        >
          <Typography
            variant="label"
            sx={{
              color: palette.neutral[500],
            }}
          >
            ORDERED BY
          </Typography>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <RadioGroup>
              {ORDERED.map((item, index) => {
                return (
                  <Box key={index}>
                    <FormControlLabel
                      value={item}
                      checked={item === ordered}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row-reverse",
                        width: "100%",
                        m: "0px !important",
                        py: "11px",
                      }}
                      control={
                        <Radio
                          sx={{
                            p: "0px !important",
                            color: palette.neutral[400],
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: palette.neutral[700],
                          }}
                        >
                          {item}
                        </Typography>
                      }
                      onClick={(e) => {
                        setOrdered(
                          (
                            e.currentTarget.children[0]
                              .children[0] as HTMLInputElement
                          ).value
                        );
                      }}
                    />
                    {index + 1 === ORDERED.length ? null : (
                      <Divider
                        sx={{
                          display: index === 2 ? "none" : "flex",
                          borderColor: palette.neutral[100],
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider
          sx={{
            borderColor: palette.neutral[100],
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <CustomButton type="fade" text="Cancel" onClick={handleClose} />
          <CustomButton type="primary" text="Apply" onClick={handleSubmit} />
        </Box>
      </Popover>
      <Modal open={open} sx={{ display: { md: "none", xs: "" } }}>
        <Slide direction="up" in={open} mountOnEnter unmountOnExit>
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid var(--light-neutral-n-100, #E9E9E9)",
              justifyContent: "space-between",
              zIndex: 12,
              bgcolor: palette.neutral.min,
              position: "fixed",
              borderRadius: "16px 16px 0 0",
              bottom: 0,
              // animation: `${popupEffect} 1s normal forwards`,
              outline: "none",
            }}
          >
            <Box sx={{ px: 2, width: { md: "360px", xs: "100%" }, mt: 2 }}>
              <Typography variant="h6">Sort by</Typography>
              <Divider
                sx={{
                  borderColor: palette.neutral[100],
                  mt: 1,
                }}
              />
              <FormControl
                sx={{
                  width: "100%",
                }}
              >
                <RadioGroup>
                  {SORT.map((item, index) => {
                    return (
                      <Box key={index}>
                        <FormControlLabel
                          value={item}
                          checked={item === sort}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row-reverse",
                            width: "100%",
                            m: "0px !important",
                            py: "11px",
                          }}
                          control={
                            <Radio
                              sx={{
                                p: "0px !important",
                                color: palette.neutral[400],
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="subtitle2"
                              sx={{
                                color: palette.neutral[700],
                              }}
                            >
                              {item}
                            </Typography>
                          }
                          onClick={(e) => {
                            setSort(
                              (
                                e.currentTarget.children[0]
                                  .children[0] as HTMLInputElement
                              ).value
                            );
                          }}
                        />
                        {index + 1 === SORT.length ? null : (
                          <Divider
                            sx={{
                              display: index === 2 ? "none" : "flex",
                              borderColor: palette.neutral[100],
                            }}
                          />
                        )}
                      </Box>
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", p: 2 }}
            >
              <CustomButton type="fade" text="Cancel" onClick={handleClose} />
              <CustomButton
                type="primary"
                text="Apply"
                onClick={handleSubmit}
              />
            </Box>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};
