import useAccountInfoStore from "store/zustand/accountInformation";

const CustomEvents = () => {
  const AccountInfo: any = useAccountInfoStore(
    (state) => state.AccountInformation
  );

  const userAgent = navigator.userAgent || navigator.vendor;

  let deviceOs;
  if (/windows phone/i.test(userAgent)) {
    deviceOs = "Windows Phone";
  } else if (/android/i.test(userAgent)) {
    deviceOs = "Android";
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    deviceOs = "iOS";
  } else if (/win/i.test(userAgent)) {
    deviceOs = "Windows";
  } else if (/mac/i.test(userAgent)) {
    deviceOs = "MacOS";
  } else {
    deviceOs = "Unknown";
  }

  const informations = {
    environment: process.env.NODE_ENV,
    UserID: AccountInfo?.id,
    FirstName: AccountInfo?.fname,
    LastName: AccountInfo?.lname,
    Role: AccountInfo?.role,
    Email: AccountInfo?.email,
    Mobile: AccountInfo?.mobile,
    Location: AccountInfo?.location,
    TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ProfileProgress: AccountInfo?.account_status?.percentage,
    DeviceType: "Web",
    DeviceOS: deviceOs,
    TaskAlertLabels: null,
  };

  return informations;
};

export default CustomEvents;
