import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Modal,
  Popover,
  Slide,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { fetchCategories } from "api/jobs";
import { CustomButton } from "components/CustomButton";
import { Icon } from "components/Icon";
import { useEffect, useState, MouseEvent } from "react";

interface CategoriesType {
  title: string;
  content: {
    id: number;
    title: string;
    created: string;
    updated: string;
    name: string;
  }[];
}

export const Services = ({ setFilter, filter }: any): JSX.Element => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [viewCategories, setViewCategories] = useState<boolean>(false);
  const [rawData, setRawData] = useState<any[]>([]);
  const [calculatedCategories, setCalculatedCategories] = useState<
    CategoriesType[]
  >([]);
  const [Categories, setCategories] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [skillTitle, setSkillTitle] = useState<any>([]);
  const [allSkills, setAllSkills] = useState<any>([]);
  let acronym = search.substring(0, 1);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);

    setCategories(filter.category);
  };

  useEffect(() => {
    allSkills.map((item: any) => {
      setSkillTitle((previousCategoris: any) => {
        return [...previousCategoris, item?.name];
      });
    });
  }, [allSkills]);

  useEffect(() => {
    if (rawData.length === 0) {
      fetchCategories().then((res) => {
        setAllSkills(
          [...res.data].sort((a, b) => (a.title > b.title ? 1 : -1))
        );
        setRawData(res.data);
      });
    }
    if (rawData.length > 0) {
      const data = rawData.reduce<CategoriesType[]>(
        (accumulator, currentValue) => {
          const title = currentValue.name[0];
          if (!accumulator[title])
            accumulator[title] = { title, content: [currentValue] };
          else accumulator[title].content.push(currentValue);
          return { ...accumulator };
        },
        {} as CategoriesType[]
      );
      const CATEGORIES = Object.values(data);
      setCalculatedCategories(CATEGORIES);
    }
  }, [rawData, Categories]);

  const handleSelectCategory = (
    e: MouseEvent<any>,
    item: string,
    id: number
  ) => {
    if (Categories && !Categories?.some((name: any) => name.title === item)) {
      setCategories((previousCategoris: any) => {
        return [...previousCategoris, { title: item, id: id }];
      });
      // setFilterCategory((prev: any) => {
      //   return [...prev, item];
      // });
    } else {
      setCategories((previousCategoris: any) => {
        const filteredCategories = previousCategoris.filter(
          (category: any) => category.title !== item
        );

        return filteredCategories;
      });
      // setFilterCategory((previousCategoris: any) => {
      //   const filteredCategories = previousCategoris.filter(
      //     (category: any) => category !== item,
      //   );

      //   return filteredCategories;
      // });
    }
  };

  const handleSubmitClick = () => {
    setFilter("category", Categories);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (filter.category) setCategories(filter.category);
  }, [filter.category]);

  return (
    <>
      <IconButton
        // disabled={disabled}
        sx={{
          height: {
            md: "35px",
          },
          display: "flex",
          alignItems: "center",
          gap: "4px",
          p: "7.5px 8px 7.5px 8px !important",
          bgcolor: palette.neutral[50],
          color: palette.neutral[900],
          borderRadius: "8px",
          transition: "color 0.3s , background-color 0.3s",
          "&:hover": {
            bgcolor: palette.neutral[100],
            transition: "color 0.3s , background-color 0.3s",
          },
          "&.Mui-focusVisible": {
            outline: `1px solid ${palette.neutral[900]}`,
          },
          ":disabled": {
            color: palette.neutral[400],
            bgcolor: palette.neutral[100],
          },
        }}
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <Icon
          iconSet="Category"
          sx={{ fontSize: "19px", color: palette.neutral[900] }}
        />
        <Typography variant="body2" sx={{ color: palette.neutral[900] }}>
          {filter.category.length > 0
            ? filter.category.length === 1
              ? `${filter.category[0].title}`
              : `${filter.category[0].title} & ${
                  filter.category.length - 1
                } more`
            : "Services"}
        </Typography>
        <Icon
          iconSet="Arrow2-Down"
          sx={{ fontSize: "19px", mt: 0.4, color: palette.neutral[900] }}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            borderRadius: "16px",
            boxShadow: "0px 2px 4px 0px #00000040",
            mt: { md: 2, xs: 4 },
            width: { md: "auto", xs: "100%" },
            display: { md: "unset", xs: "none" },
          },
        }}
      >
        <Box sx={{ pt: 1, width: { md: "624px", xs: "100%" }, px: 2 }}>
          <Box sx={{ display: "flex", gap: 2, pb: 1 }}>
            <TextField
              fullWidth
              placeholder="Search"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
              sx={{
                width: "100%",
                "& .MuiInputBase-root": {
                  height: 38,
                  borderRadius: "8px",
                  backgroundColor: palette.neutral[50],
                  color: palette.neutral[500],
                },
                fieldset: {
                  border: "unset",
                },
                input: {
                  p: 0,
                },
                MuiInput: {
                  input: {
                    "&::placeholder": {
                      color: palette.neutral[500],
                    },
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      iconSet="Search"
                      sx={{ fontSize: "22px", color: palette.neutral[500] }}
                    />
                  </InputAdornment>
                ),
                endAdornment: search ? (
                  <InputAdornment position="end">
                    <Icon
                      iconSet="Close"
                      sx={{
                        color: palette.neutral[500],
                        mr: -0.5,
                        cursor: "pointer",
                        borderRadius: "100%",
                        fontSize: "22px",
                      }}
                      handleClick={() => {
                        setSearch("");
                      }}
                    />
                  </InputAdornment>
                ) : null,
              }}
            />
            <CustomButton
              type="empty"
              text="Deselect all"
              onClick={() => setCategories([])}
              disabled={Categories.length < 1}
            />
          </Box>
          <Box sx={{ overflowY: "overlay", height: "340px" }}>
            <Box
              sx={{
                columnCount: { md: "2", xs: "1" },
                mt: 2,
                gap: 4,
              }}
            >
              {calculatedCategories.map((category: any, index: any) => {
                if (
                  skillTitle
                    .map((item: any) => {
                      return item.toLowerCase().indexOf(search) > -1;
                    })
                    .includes(true)
                )
                  if (
                    category.title.toLowerCase().includes(acronym.toLowerCase())
                  )
                    return (
                      <Box key={index}>
                        <ListSubheader
                          sx={{
                            color: palette.neutral.max,
                            p: 0,
                            mt: category.title === "A" ? -1 : 2,
                            mb: 1,
                            width: "100%",
                            bgcolor: "unset",
                          }}
                        >
                          {/* <Typography
                            variant="subtitle1"
                            sx={{ color: palette.neutral[900] }}
                          >
                            {category.title}
                          </Typography>{" "} */}
                          <Divider
                            sx={{
                              display: category.title === "A" ? "none" : "",
                              borderColor: palette.neutral[100],
                            }}
                          />
                        </ListSubheader>
                        {calculatedCategories[index].content?.map(
                          (item: any, categoryIndex: any) => {
                            if (
                              item.name
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            ) {
                              return (
                                <Box key={categoryIndex}>
                                  <MenuItem
                                    disableRipple
                                    value={categoryIndex}
                                    onClick={(e) =>
                                      handleSelectCategory(
                                        e,
                                        item.name,
                                        item.id
                                      )
                                    }
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      px: "0px !important",
                                      py: "8px !important",
                                      ":hover": {
                                        backgroundColor: palette.neutral.min,
                                      },
                                      "&.Mui-selected": {
                                        backgroundColor: palette.neutral.min,
                                      },
                                      "&.Mui-selected:hover": {
                                        backgroundColor: palette.neutral.min,
                                      },
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      sx={{
                                        color: palette.neutral[700],
                                      }}
                                    >
                                      {item.name}
                                    </Typography>

                                    <Checkbox
                                      {...label}
                                      checked={
                                        Categories &&
                                        Categories?.some(
                                          (name: any) => name?.id === item.id
                                        )
                                      }
                                      sx={{ p: "0px !important" }}
                                    />
                                  </MenuItem>
                                </Box>
                              );
                            }
                          }
                        )}
                      </Box>
                    );
              })}
            </Box>
          </Box>
        </Box>
        <Divider
          sx={{
            borderColor: palette.neutral[100],
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <CustomButton type="fade" text="Cancel" onClick={handleClose} />
          <CustomButton
            type="primary"
            text="Apply"
            onClick={handleSubmitClick}
          />
        </Box>
      </Popover>
      <Modal open={open} sx={{ display: { md: "none", xs: "" } }}>
        <Slide direction="up" in={open} mountOnEnter unmountOnExit>
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid var(--light-neutral-n-100, #E9E9E9)",
              justifyContent: "space-between",
              zIndex: 12,
              bgcolor: palette.neutral.min,
              position: "fixed",
              borderRadius: "16px 16px 0 0",
              bottom: 0,
              // animation: `${popupEffect} 1s normal forwards`,
              outline: "none",
            }}
          >
            <Box sx={{ pt: 1, width: { md: "624px", xs: "100%" }, px: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">Services</Typography>
                <CustomButton
                  type="empty"
                  text="Deselect all"
                  onClick={() => setCategories([])}
                  disabled={Categories.length < 1}
                />
              </Box>
              <Divider
                sx={{
                  borderColor: palette.neutral[100],
                  mt: 0.5,
                  mb: "12px",
                }}
              />
              <Box sx={{ display: "flex", gap: 2, pb: 1 }}>
                <TextField
                  fullWidth
                  placeholder="Search"
                  value={search}
                  onChange={(e: any) => setSearch(e.target.value)}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      height: 38,
                      borderRadius: "8px",
                      backgroundColor: palette.neutral[50],
                      color: palette.neutral[500],
                    },
                    fieldset: {
                      border: "unset",
                    },
                    input: {
                      p: 0,
                    },
                    MuiInput: {
                      input: {
                        "&::placeholder": {
                          color: palette.neutral[500],
                        },
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon
                          iconSet="Search"
                          sx={{ fontSize: "22px", color: palette.neutral[500] }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: search ? (
                      <InputAdornment position="end">
                        <Icon
                          iconSet="Close"
                          sx={{
                            color: palette.neutral[500],
                            mr: -0.5,
                            cursor: "pointer",
                            borderRadius: "100%",
                            fontSize: "22px",
                          }}
                          handleClick={() => {
                            setSearch("");
                          }}
                        />
                      </InputAdornment>
                    ) : null,
                  }}
                />
              </Box>
              <Box sx={{ overflowY: "overlay", height: "340px" }}>
                <Box
                  sx={{
                    columnCount: { md: "2", xs: "1" },
                    mt: 2,
                    gap: 4,
                  }}
                >
                  {calculatedCategories.map((category: any, index: any) => {
                    if (
                      skillTitle
                        .map((item: any) => {
                          return item.toLowerCase().indexOf(search) > -1;
                        })
                        .includes(true)
                    )
                      if (
                        category.title
                          .toLowerCase()
                          .includes(acronym.toLowerCase())
                      )
                        return (
                          <Box key={index}>
                            <ListSubheader
                              sx={{
                                color: palette.neutral.max,
                                p: 0,
                                mt: category.title === "A" ? 0 : 2,
                                mb: 1,
                                width: "100%",
                                bgcolor: "unset",
                              }}
                            >
                              {/* <Typography
                                variant="subtitle1"
                                sx={{ color: palette.neutral[900] }}
                              >
                                {category.title}
                              </Typography>{" "} */}
                              <Divider
                                sx={{
                                  display: category.title === "A" ? "none" : "",
                                  borderColor: palette.neutral[100],
                                }}
                              />
                            </ListSubheader>
                            {calculatedCategories[index].content?.map(
                              (item: any, categoryIndex: any) => {
                                if (
                                  item.name
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                                ) {
                                  return (
                                    <Box key={categoryIndex}>
                                      <MenuItem
                                        disableRipple
                                        value={categoryIndex}
                                        onClick={(e) =>
                                          handleSelectCategory(
                                            e,
                                            item.name,
                                            item.id
                                          )
                                        }
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          px: "0px !important",
                                          py: "8px !important",
                                          ":hover": {
                                            backgroundColor:
                                              palette.neutral.min,
                                          },
                                          "&.Mui-selected": {
                                            backgroundColor:
                                              palette.neutral.min,
                                          },
                                          "&.Mui-selected:hover": {
                                            backgroundColor:
                                              palette.neutral.min,
                                          },
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={{
                                            color: palette.neutral[700],
                                          }}
                                        >
                                          {item.name}
                                        </Typography>

                                        <Checkbox
                                          {...label}
                                          checked={
                                            Categories &&
                                            Categories?.some(
                                              (name: any) =>
                                                name?.id === item.id
                                            )
                                          }
                                          sx={{ p: "0px !important" }}
                                        />
                                      </MenuItem>
                                    </Box>
                                  );
                                }
                              }
                            )}
                          </Box>
                        );
                  })}
                </Box>
              </Box>
            </Box>
            <Divider
              sx={{
                borderColor: palette.neutral[100],
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 2,
              }}
            >
              <CustomButton type="fade" text="Cancel" onClick={handleClose} />
              <CustomButton
                type="primary"
                text="Apply"
                onClick={handleSubmitClick}
              />
            </Box>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};
