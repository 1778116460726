import {
  Avatar,
  Box,
  Button,
  Divider,
  Fade,
  IconButton,
  MenuItem,
  Modal,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { isDevelopment } from "utils";
import { connect, useDispatch, useSelector } from "react-redux";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { MainNav } from "./components/MainNav";
import { Icon } from "components/Icon";
import { MainNavMobile } from "./components/MainNavMobile";
import LocalLoadingPages from "components/LocalLoadingPages";
import Cookies from "js-cookie";
import { CustomButton } from "components/CustomButton";
import toast from "react-hot-toast";
import AlertNotif from "components/AlertNotif";
import OneSignal from "react-onesignal";
import CustomEvents from "components/CustomEvents";
import WhatsNew from "components/WhatsNew";
import useAccountInfoStore from "store/zustand/accountInformation";
import { Filters } from "pages/exploreJob/components/Filter";
import useFilterStore from "store/zustand/filter";
import { GetVerifiedModal } from "components/GetVerifiedModal";
import { CircularProgressWithBackground } from "components/GetVerifiedProgress";
declare global {
  interface Window {
    analytics: any;
  }
}
export interface AccountType {
  id: number;
  avatar: { thumb_url: string; url: string };
  fname: string;
  lname: string;
  skills: any;
  longitude?: any;
  location?: string;
  position?: any;
}
interface AppLayoutType {
  children: any;
  account: AccountType;
  notificationCount: any;
}
const AppLayout = ({
  children,
  account,
  notificationCount,
}: AppLayoutType): JSX.Element => {
  const { palette, breakpoints } = useTheme();
  const defaultValues = CustomEvents();
  const {
    location: { pathname },
    location,
    push,
  } = useHistory();

  const isOther =
    pathname.startsWith("/profile") &&
    pathname.split("/")[2] &&
    pathname.split("/")[2] !== String(account.id)
      ? true
      : false;
  const { mode } = useSelector(
    (state: { mode: { mode: string } }) => state.mode
  );
  const AccountInfo: any = useAccountInfoStore(
    (state) => state.AccountInformation
  );
  const openVerified: any = useAccountInfoStore((state) => state.openVerified);
  const setOpenVerified: any = useAccountInfoStore(
    (state) => state.setOpenVerified
  );

  const filter = useFilterStore((state: any) => state.filter);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(breakpoints.down("md"));
  const isExplore = pathname.startsWith("/explore");
  const isPostJob = pathname.startsWith("/post-task/Title");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [logoutModal, setLogoutModal] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const buttonRef = useRef(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMode = useCallback(() => {
    if (mode === "ticker") {
      dispatch({ type: "poster" });
      // push("/inventory");
    } else {
      dispatch({ type: "ticker" });
    }
    handleClose();
  }, [mode, dispatch]);

  useEffect(() => {
    if (pathname.startsWith("/explore")) {
      dispatch({ type: "ticker" });
    } else if (pathname.startsWith("/inventory")) {
      dispatch({ type: "poster" });
    }
  }, []);

  useEffect(() => {
    if (mode === "poster") {
      if (pathname.startsWith("/explore")) {
        push("/inventory");
      } else if (pathname.startsWith("/activity") && !isMobile) {
        push("/inventory");
      } else if (pathname.startsWith("/workspace")) {
        push("/inventory");
      } else if (pathname.startsWith("/profile-") && location.search) {
        push(`/profile-poster${location.search}`);
      } else if (pathname.startsWith("/profile-")) {
        push("/profile-poster");
      }
    }
    if (mode === "ticker") {
      if (pathname.startsWith("/inventory")) {
        push("/explore-tasks");
      } else if (pathname.startsWith("/activity") && !isMobile) {
        push("/explore-tasks");
      } else if (
        pathname.startsWith("/profile-public") &&
        location.search.indexOf("tp-") < 0
      ) {
        push(`/profile-public`);
      } else if (
        pathname.startsWith("/profile-") &&
        location.search.indexOf("tp-") > -1
      ) {
        push(`/profile-public${location.search}`);
      } else if (
        pathname.startsWith("/profile-") &&
        location.search.indexOf("tp-") < 0
      ) {
        push(`/profile-tasker${location.search}`);
      }
    }
  }, [mode, push, isMobile, pathname]);

  useEffect(() => {
    if (pathname === "/profile-public" && mode === "poster") {
      push("/inventory");
    }
  }, [mode, location]);

  const handleLogoClick = () => {
    if (mode === "ticker") {
      push("/explore-tasks");
    } else if (mode === "poster") {
      push("/inventory");
    }
  };

  const [anchorElVeri, setAnchorElVeri] = useState<HTMLButtonElement | null>(
    buttonRef.current
  );

  const handleClickVeri = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElVeri(event.currentTarget);
    setOpenVerified("open");
  };

  const handleCloseVeri = () => {
    // setAnchorElVeri(null);
    setOpenVerified("");
  };

  useEffect(() => {
    setAnchorElVeri(buttonRef.current);
  }, [buttonRef]);

  const openVeri = Boolean(anchorElVeri);
  const idVeri = openVeri ? "simple-popover" : "simple-popover";

  console.log();

  return (
    <Box>
      {!isMobile && (
        <Box
          sx={
            {
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "space-between",
              // px: { lg: isExplore ? 2 : 14.5, md: isExplore ? 2 : 9 },
              // borderBottom: "1px solid " + palette.neutral[100],
              // visibility: pathname === "/" ? "hidden" : "visible",
            }
          }
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: 52,
              // borderBottom:
              //   pathname === "/explore-tasks"
              //     ? `1px solid ${palette.neutral[100]}`
              //     : "",
              px: { lg: isExplore ? 2 : 14.5, md: isExplore ? 2 : 9 },
            }}
          >
            {mode === "ticker" &&
            AccountInfo &&
            AccountInfo !== undefined &&
            (AccountInfo?.skills?.skills?.length < 1 ||
              !AccountInfo.position) &&
            !pathname.startsWith("/profile-public") ? (
              <WhatsNew />
            ) : null}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box
                component="img"
                src={
                  isDevelopment
                    ? "/images/dev-logo.svg"
                    : "/images/taskpin-logo.svg"
                }
                alt="Taskpin"
                width={96}
                height={24}
                sx={{
                  mr: 4,
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={handleLogoClick}
              />
              {/* {mode === "ticker" && (
              <CustomButton
                text="Set a Task Alert"
                type="rounded"
                icon="AlertNotif"
                sx={{
                  border: `2px solid ${palette.secondary.main}`,
                  color: palette.secondary.main,
                }}
              />
            )} */}
              {mode === "poster" && (
                <CustomButton
                  text="Pin a Task"
                  type="rounded"
                  icon="Add"
                  onClick={() => {
                    push("/post-task?Title");
                    Cookies.set("postTask", JSON.stringify({ title: "" }));
                    window.analytics.track(
                      `webapp poster post task button / header`,
                      defaultValues
                    );
                  }}
                />
              )}
              {mode === "ticker" && (
                <CustomButton
                  text="Task Alerts"
                  type="rounded"
                  icon="CircleNotifications"
                  onClick={() => {
                    push({ search: "alert" });
                  }}
                  sx={{
                    color: palette.secondary.main,
                    border: `2px solid ${palette.secondary.main}`,
                    "&:hover": {
                      bgcolor: palette.secondary[50],
                      transition: "background-color 0.3s",
                    },
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "grid",
              }}
            >
              <MainNav mode={mode} isOther={isOther} />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                button: {
                  display: "flex",
                  justifyContent: "center",
                  mr: 1,
                  color: palette.neutral[700],
                },
              }}
            >
              {/* <Box
              sx={{
                py: 1,
                px: 2,
                borderRadius: "8px",
                bgcolor: "#FFDE71",
                display: "flex",
                alignItems: "center",
                height: "35px",
                mr: 2,
              }}
            >
              <Icon iconSet="HintBulb" sx={{ fontSize: "19px", mr: "4px" }} />
              <Typography variant="button">Tips</Typography>
            </Box> */}
              <span onClick={handleClick}>
                <Box
                  aria-describedby={id}
                  sx={{
                    border: `2px solid ${
                      mode === "ticker"
                        ? palette.secondary.main
                        : palette.primary.main
                    }`,
                    borderRadius: "8px",
                    display: "flex",
                    p: "2px",
                  }}
                >
                  {account?.avatar?.thumb_url ? (
                    <Avatar
                      src={
                        account?.avatar?.thumb_url
                          ? account?.avatar?.thumb_url
                          : "/images/Theme=Light, Size=32x32, State=Guest.svg"
                      }
                      sx={{
                        minWidth: 32,
                        maxWidth: 32,
                        minHeight: 32,
                        maxHeight: 32,
                        position: "relative",
                        overflow: "unset",
                        img: { borderRadius: "4px" },
                        borderRadius: "4px",
                        bgcolor: palette.neutral[50],
                        color: palette.neutral[300],
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        minWidth: 32,
                        maxWidth: 32,
                        minHeight: 32,
                        maxHeight: 32,
                        position: "relative",
                        overflow: "unset",
                        borderRadius: "4px",
                        bgcolor: palette.neutral[50],
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Icon
                        iconSet="User-Filled"
                        sx={{
                          color: palette.neutral[300],
                          fontSize: { md: "20px", xs: "24px" },
                        }}
                      />
                    </Box>
                  )}
                  <Box
                    sx={{
                      minWidth: 32,
                      maxWidth: 32,
                      minHeight: 32,
                      maxHeight: 32,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Icon iconSet="Arrow2-Down" sx={{ fontSize: "19px" }} />
                  </Box>
                </Box>
              </span>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  ".MuiPopover-paper": {
                    boxShadow: "0px 1px 4px 0px #00000026",
                    mt: "12px",
                    borderRadius: "8px",
                  },
                }}
              >
                <Box p={2}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      src={
                        account?.avatar?.thumb_url
                          ? account.avatar.thumb_url
                          : "/images/Theme=Light, Size=32x32, State=Guest.svg"
                      }
                      sx={{
                        minWidth: 32,
                        maxWidth: 32,
                        minHeight: 32,
                        maxHeight: 32,
                        position: "relative",
                        overflow: "unset",
                        img: { borderRadius: "100%" },

                        bgcolor: palette.neutral[50],
                        color: palette.neutral[300],
                        objectFit: "cover",
                        mr: 1,
                      }}
                    />
                    <Typography variant="subtitle2">
                      {account.fname} {account.lname}
                    </Typography>
                  </Box>
                  <CustomButton
                    text={
                      mode === "ticker"
                        ? "Switch to Pinner"
                        : "Switch to Tasker"
                    }
                    type="rounded"
                    sx={{
                      width: "100%",
                      mt: 1,
                      border:
                        mode === "ticker"
                          ? `2px solid ${palette.primary.main}`
                          : `2px solid ${palette.secondary.main}`,
                      color:
                        mode === "ticker"
                          ? palette.primary.main
                          : palette.secondary.main,
                      "&:hover": {
                        bgcolor:
                          mode !== "ticker"
                            ? palette.secondary.main
                            : palette.primary.main,
                        color: palette.neutral.min,
                      },
                    }}
                    onClick={handleMode}
                  />
                </Box>
                <Divider />
                <Box>
                  <MenuItem
                    onClick={() => {
                      push("/profile-public");
                      handleClose();
                    }}
                    sx={{ display: mode === "ticker" ? "" : "none" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Icon
                        iconSet="AccountCircle"
                        sx={{ fontSize: "24px", mr: 1 }}
                      />
                      <Typography variant="body1">
                        View Public Profile
                      </Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      if (mode === "ticker") {
                        push("/profile-tasker");
                      } else {
                        push("/profile-poster");
                      }
                      handleClose();
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Icon
                        iconSet="Settings"
                        sx={{ fontSize: "24px", mr: 1 }}
                      />
                      <Typography variant="body1">Profile Settings</Typography>
                    </Box>
                  </MenuItem>
                </Box>
                <Divider />
                <MenuItem onClick={() => setLogoutModal(true)}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      iconSet="Logout"
                      sx={{
                        fontSize: "24px",
                        mr: 1,
                        color: palette.error.main,
                      }}
                    />
                    <Typography variant="body1" color={palette.error.main}>
                      Logout
                    </Typography>
                  </Box>
                </MenuItem>
              </Popover>
            </Box>
          </Box>
          {pathname.indexOf("explore-tasks") > -1 && filter.show ? (
            <Box>
              <Filters />
            </Box>
          ) : null}
        </Box>
      )}
      {logoutModal ? (
        <Modal
          open={true}
          onClose={() => {
            setLogoutModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Fade in={logoutModal}>
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { md: "363px", sm: "65%", xs: "304px" },
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: "8px",
                py: 3,
                px: 4,
              }}
            >
              <Typography component="header" variant="h5" sx={{ mb: 1 }}>
                Come back soon!
              </Typography>
              <Typography
                component="span"
                variant="body1"
                sx={{ color: palette.neutral[600] }}
              >
                Are you sure you want to logout?
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
                <Button
                  onClick={() => setLogoutModal(false)}
                  sx={{ height: { md: 35, xs: 40 } }}
                >
                  <Typography
                    component="span"
                    variant="button"
                    sx={{ color: palette.primary.main }}
                  >
                    Cancel
                  </Typography>
                </Button>
                <Button
                  onClick={() => {
                    if (isDevelopment) {
                      OneSignal.logout();
                      Cookies.remove("token");
                      Cookies.remove("thirdPartyLogin");
                      localStorage.removeItem("token");
                      localStorage.removeItem("isLoggedIn");
                      toast.custom((t) => (
                        <AlertNotif
                          title="You have been successfully logged out"
                          type="success"
                          target={t}
                        />
                      ));
                      window.location.href = "https://dev.taskpin.ca/logout";
                    } else {
                      OneSignal.logout();
                      localStorage.removeItem("token");
                      localStorage.removeItem("isLoggedIn");
                      Cookies.remove("token", {
                        domain: window.location.origin.includes("localhost")
                          ? undefined
                          : ".taskpin.ca",
                      });
                      Cookies.remove("thirdPartyLogin", {
                        domain: window.location.origin.includes("localhost")
                          ? undefined
                          : ".taskpin.ca",
                      });
                      Cookies.remove("token");
                      toast.custom((t) => (
                        <AlertNotif
                          title="You have been successfully logged out"
                          type="success"
                          target={t}
                        />
                      ));
                      window.location.href = "https://taskpin.ca/logout";
                    }
                  }}
                  sx={{
                    "&:hover": {
                      bgcolor: palette.error.light,
                    },
                    height: { md: 35, xs: 40 },
                  }}
                >
                  <Typography
                    component="span"
                    variant="button"
                    sx={{
                      color: palette.error.main,
                    }}
                  >
                    Logout
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      ) : null}
      <Box>
        <Suspense
          fallback={
            <LocalLoadingPages />
            // <Spinner
            //   type="box"
            //   label="Getting ready..."
            //   className="dark"
            //   style={undefined}
            //   forwardref={undefined}
            // />
          }
        >
          {children}
          <GetVerifiedModal
            id={idVeri}
            open={openVerified && mode === "ticker"}
            anchorEl={anchorElVeri}
            handleClose={handleCloseVeri}
          />
          {AccountInfo?.account_status?.percentage < 99 &&
          mode === "ticker" &&
          openVerified &&
          pathname.startsWith("/explore-tasks") === false &&
          pathname.startsWith("/messaging") === false &&
          !isMobile ? (
            <IconButton
              ref={buttonRef}
              sx={{
                bgcolor: palette.secondary.main,
                position: "absolute",
                bottom: 20,
                right: 20,
                borderRadius: "32px",
                px: "12.5px !important",
                py: "12.5px !important",
                display: "flex",
                alignItems: "center",
                "&:hover": {
                  cursor: "pointer",
                },
                zIndex: 999,
              }}
              onClick={() => {
                setOpenVerified("");
              }}
              aria-describedby={idVeri}
            >
              <Icon
                iconSet="Close"
                sx={{ color: palette.neutral.min, fontSize: "30px" }}
              />
            </IconButton>
          ) : AccountInfo?.account_status?.percentage < 99 &&
            pathname.startsWith("/explore-tasks") === false &&
            pathname.startsWith("/messaging") === false &&
            mode === "ticker" &&
            !openVerified &&
            !isMobile ? (
            <IconButton
              ref={buttonRef}
              sx={{
                bgcolor: palette.secondary.main,
                position: "absolute",
                bottom: 20,
                right: 20,
                borderRadius: "32px",
                py: 1,
                pl: 2,
                pr: 1,
                display: "flex",
                alignItems: "center",
                "&:hover": {
                  cursor: "pointer",
                  bgcolor: palette.secondary.main,
                },
                zIndex: 999,
              }}
              aria-describedby={idVeri}
              onClick={handleClickVeri}
            >
              <Typography
                variant="subtitle2"
                sx={{ color: palette.neutral.min, mr: 1 }}
              >
                Get Verified
              </Typography>

              <CircularProgressWithBackground
                value={AccountInfo?.account_status?.percentage?.toFixed(2)}
              />
            </IconButton>
          ) : null}
        </Suspense>
      </Box>
      {isMobile && (
        <Box
          sx={{
            display: isPostJob ? "none" : "flex",
            px: 1.5,
            pt: 1,
            pb: 0.5,
            bottom: 0,
            zIndex: 800,
            position: "fixed",
            borderTop: "1px solid " + palette.neutral[100],
            width: "100%",
            height: "56px",
            backgroundColor: palette.neutral.min,
            visibility: pathname === "/underMaintenance" ? "hidden" : "visible",
          }}
        >
          <MainNavMobile mode={mode} isOther={isOther} account={account} />
        </Box>
      )}
    </Box>
  );
};
function mapStateToProps(state: any) {
  return {
    notificationCount: state.notification,
    wallet: state.wallet,
  };
}

export default connect(mapStateToProps, null)(AppLayout);
