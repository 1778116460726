import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Step } from "../Step";
import { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import AlertNotif from "components/AlertNotif";
import { useHistory, useLocation } from "react-router-dom";

export const EditAddress = ({
  handleStep,
  setLocationData,
  locationData,
  setTaskData,
}: any): JSX.Element => {
  const { palette } = useTheme();
  const [houseNumber, setHouseNumber] = useState<string>("");
  const [streetName, setStreetName] = useState<string>("");
  const history = useHistory();
  const location = useLocation();

  const loc = locationData?.place_name?.substring(
    0,
    locationData?.place_name?.indexOf(",")
  );

  useEffect(() => {
    if (/^[+-]?\d+$/.test(loc.match(/^(\S+)\s(.*)/)?.slice(1)[0])) {
      setHouseNumber(loc.match(/^(\S+)\s(.*)/)?.slice(1)[0]);
    } else {
      setHouseNumber("");
    }
  }, [loc]);

  const placeName = locationData?.place_name.replace(
    loc?.split(" ")[0],
    streetName
  );

  const houseNumberText =
    houseNumber +
    " " +
    placeName?.replace(
      placeName.substring(0, placeName.indexOf(",")),
      streetName
    );

  useEffect(() => {
    placeName.replace(loc?.split(" ")[0], streetName);
  }, [streetName]);

  useEffect(() => {
    setStreetName(
      loc
        .replace(/[0-9]/g, "")
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .trimStart()
    );
  }, [loc]);

  const handleSaveAddressChange = () => {
    setLocationData((current: any) => ({
      ...current,
      place_name: houseNumberText,
    }));
    setTaskData((current: any) => ({
      ...current,
      location: houseNumberText,
    }));
    setTaskData((current: any) => ({
      ...current,
      location: houseNumberText,
    }));
    toast.custom((t) => (
      <AlertNotif
        title={"Your address has been successfully saved"}
        type="success"
        target={t}
      />
    ));
    history.push({ search: "Location" });
  };

  return (
    <Step title="Location">
      <Toaster position="top-right" reverseOrder={false} />
      <Box sx={{ width: { xs: "100%", sm: "558px" }, m: "0 auto" }}>
        <Typography variant="h5">Edit Your Address</Typography>
      </Box>
      <Grid
        container
        sx={{
          width: { xs: "100%", sm: "558px" },
          m: "0 auto",
          mt: "16px",
          display: "flex",
        }}
      >
        <Grid item md={6} xs={12}>
          <Box sx={{ pr: { md: "8px" } }}>
            <TextField
              sx={{ width: "100%" }}
              id="outlined-basic"
              label="House Number"
              variant="outlined"
              value={houseNumber}
              onChange={(e) => {
                setHouseNumber(e?.target?.value.replace(/[^0-9]/g, ""));
              }}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12} sx={{ mt: { md: 0, xs: "32px" } }}>
          <Box sx={{ pl: { md: "8px" } }}>
            <TextField
              sx={{ width: "100%" }}
              id="outlined-basic"
              label="Street Number"
              variant="outlined"
              value={streetName}
              onChange={(e) => {
                setStreetName(e?.target?.value);
              }}
            />
          </Box>
        </Grid>
        <Box
          sx={{
            mt: { md: "16px", xs: "24px" },
            display: "flex",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <Button
            sx={{
              border: `1px solid ${palette.neutral[400]}`,
              py: "4px !important",
              px: "16px !important",
              mr: "16px",
              height: { md: 35, xs: 40 },
            }}
            onClick={() => history.push({ search: "Location" })}
          >
            <Typography variant="button">Cancel</Typography>
          </Button>
          <Button
            sx={{
              "&:hover": { backgroundColor: palette.primary[100] },
              bgcolor: palette.primary[50],
              py: "4px !important",
              px: "16px !important",
              height: { md: 35, xs: 40 },
            }}
            onClick={handleSaveAddressChange}
          >
            <Typography
              component="span"
              variant="button"
              sx={{
                color: palette.primary.main,
              }}
            >
              Save Changes
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Step>
  );
};
