import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
  keyframes,
  useTheme,
} from "@mui/material";
import { useContext, useState } from "react";
import { Neighborhood } from "./Components/Naighborhood";
import { Services } from "./Components/Services";
import { Price } from "./Components/Price";
import ExploreJobContext from "../Context";
import { Icon } from "components/Icon";
import { CustomButton } from "components/CustomButton";
import { Sort } from "./Components/Sort";
import useFilterStore from "store/zustand/filter";
import { Switch } from "../Switch";
import { useHistory } from "react-router-dom";
import AlertNotif from "components/AlertNotif";
import { createJobAlert } from "api/exploreJob";
import toast from "react-hot-toast";

const SORT = ["Most Recent", "Price", "Due date"];
const ORDERED = ["Ascending", "Descending"];

let requestQueue: ReturnType<typeof setTimeout> | undefined;
export const Filters = (): JSX.Element => {
  const { palette } = useTheme();
  const myEffect = keyframes`
  0% {
    transform: rotate(0deg);

  }
  100% {
    transform: rotate(360deg);
  }
`;
  const { push } = useHistory();
  const { setViewList } = useContext(ExploreJobContext);
  const setFilter = useFilterStore((state: any) => state.setFilter);
  const filter = useFilterStore((state: any) => state.filter);
  const [filterAlert, setFilterAlert] = useState(false);
  const [filterAlertLoading, setFilterAlertLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [sort, setSort] = useState("");
  const [ordered, setOrdered] = useState("");

  const handleClose = () => {
    setOpen(false);

    setOrdered(filter.sort_direction);
    if (filter.sort_by === "budget") {
      setSort("Price");
    } else {
      setSort(filter.sort_by);
    }
  };

  const handleSubmit = () => {
    if (sort === "Price") {
      setFilter("sort_by", "budget");
    } else setFilter("sort_by", sort);

    setOpen(false);
  };

  const formatServices = (arr: any) => {
    if (arr.length <= 2) {
      return arr.join(", ");
    }
    const firstTwo = arr.slice(0, 2).join(", ");
    const remainingCount = arr.length - 2;
    return `${firstTwo} & ${remainingCount} more`;
  };

  const handleAddAlert = () => {
    const dto = {
      title: formatServices(filter.category.map((item: any) => item.title)),
      app_notification: 1,
      email_notification: 1,
      task_type: filter.task_type?.toLowerCase(),
      category_id: filter.category.map((item: any) => item.id),
      distance: filter.distance,
      latitude: filter.current_lat,
      location: filter.location,
      longitude: filter.current_lng,
      max_price: filter.max_price,
      min_price: filter.min_price,
      neighborhood: filter.location,
    };

    if (filter.category.length === 0) {
      toast.custom((t) => (
        <AlertNotif
          title={"At least one service must be selected for an alert"}
          type="primary"
          target={t}
        />
      ));
    } else if (filter.category.length > 10) {
      toast.custom((t) => (
        <AlertNotif
          title={"A maximum of 10 services are allowed in an alert"}
          type="primary"
          target={t}
        />
      ));
    } else {
      if (filterAlert !== true) {
        setFilterAlertLoading(true);
        toast.promise(
          createJobAlert(dto)
            .then((res) => {
              toast.custom((t) => (
                <AlertNotif title={res.message} type="success" target={t} />
              ));
              setFilterAlert(true);
              setFilterAlertLoading(false);
            })
            .catch((err) => {
              toast.custom((t) => (
                <AlertNotif
                  title={err.response.data.message}
                  type="error"
                  target={t}
                />
              ));
              setFilterAlert(false);
              setFilterAlertLoading(false);
            }),
          {
            loading: "Loading...",
            success: (res) => ``,
            error: (err) => ``,
          },
          {
            style: {
              backgroundColor: palette.primary[50],
              borderBottom: `2px solid ${palette.primary.main}`,
            },
            loading: {
              icon: (
                <Icon
                  iconSet="Cached"
                  sx={{
                    color: palette.primary.main,
                    animation: `${myEffect} 1s normal infinite`,
                  }}
                />
              ),
            },
            success: {
              style: { display: "none" },
            },
            error: {
              style: { display: "none" },
            },
          }
        );
      } else {
        setFilterAlert(false);
      }
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: { md: "54px", xs: "95px" },
        mb: { xs: 1, md: 0 },
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: { md: "center" },
        borderTop: { md: "1px solid " + palette.neutral[100] },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          mb: { xs: 1.5, md: 0 },
          px: { lg: 2, md: 2 },
        }}
      >
        <TextField
          fullWidth
          placeholder="Search"
          value={filter.search}
          onChange={(e) => {
            setFilter("search", e.target.value);
            window.analytics.track(`web search ${e.target.value}`);
            setViewList(false);
          }}
          sx={{
            width: { xs: "100%", md: "280px", lg: "360px" },

            "& .MuiInputBase-root": {
              height: 38,
              borderRadius: "8px",
              backgroundColor: palette.neutral[50],
              color: palette.neutral[500],
            },
            fieldset: {
              border: "unset",
            },
            input: {
              p: 0,
            },
            MuiInput: {
              input: {
                "&::placeholder": {
                  color: palette.neutral[500],
                },
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon
                  iconSet="Search"
                  sx={{ fontSize: "22px", color: palette.neutral[500] }}
                />
              </InputAdornment>
            ),
            endAdornment: filter.search ? (
              <InputAdornment position="end">
                <Icon
                  iconSet="Close"
                  sx={{
                    color: palette.neutral[500],
                    mr: -0.5,
                    cursor: "pointer",
                    borderRadius: "100%",
                    fontSize: "22px",
                  }}
                  handleClick={() => {
                    setFilter("search", "");
                  }}
                />
              </InputAdornment>
            ) : null,
          }}
        />
        <Box
          sx={{
            p: "9.5px",
            bgcolor: palette.neutral[50],
            borderRadius: "8px",
            minWidth: "38px",
            minHeight: "38px",
            maxWidth: "38px",
            maxHeight: "38px",
            display: { md: "none" },
          }}
          onClick={() => setOpen(true)}
        >
          <Icon iconSet="Sort" sx={{ fontSize: "19px" }} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          overflowX: { md: "unset", xs: "overlay" },
          overflowY: "hidden",
          ml: { md: -1 },
        }}
      >
        <Services setFilter={setFilter} filter={filter} />
        <Neighborhood setFilter={setFilter} filter={filter} />
        <Price setFilter={setFilter} filter={filter} />
        <Sort setFilter={setFilter} filter={filter} />
      </Box>
      <Modal open={open} sx={{ display: { md: "none", xs: "" } }}>
        <Slide direction="up" in={open} mountOnEnter unmountOnExit>
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid var(--light-neutral-n-100, #E9E9E9)",
              justifyContent: "space-between",
              zIndex: 12,
              bgcolor: palette.neutral.min,
              position: "fixed",
              borderRadius: "16px 16px 0 0",
              bottom: 0,
              // animation: `${popupEffect} 1s normal forwards`,
              outline: "none",
            }}
          >
            <Box sx={{ px: 2, width: { md: "360px", xs: "100%" }, mt: 2 }}>
              <Typography variant="h6">Sort by</Typography>
              <FormControl
                sx={{
                  width: "100%",
                }}
              >
                <RadioGroup>
                  {SORT.map((item, index) => {
                    return (
                      <Box key={index}>
                        <FormControlLabel
                          value={item}
                          checked={item === sort}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row-reverse",
                            width: "100%",
                            m: "0px !important",
                            py: "11px",
                          }}
                          control={
                            <Radio
                              sx={{
                                p: "0px !important",
                                color: palette.neutral[400],
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="subtitle2"
                              sx={{
                                color: palette.neutral[700],
                              }}
                            >
                              {item}
                            </Typography>
                          }
                          onClick={(e) => {
                            setSort(
                              (
                                e.currentTarget.children[0]
                                  .children[0] as HTMLInputElement
                              ).value
                            );
                          }}
                        />
                        {index + 1 === SORT.length ? null : (
                          <Divider
                            sx={{
                              display: index === 2 ? "none" : "flex",
                              borderColor: palette.neutral[100],
                            }}
                          />
                        )}
                      </Box>
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Box>
            <Divider
              sx={{
                height: 8,
                backgroundColor: palette.neutral[50],
                borderBottom: "unset",
                borderRadius: "4px",
                mx: "16px",
                display: sort === "Most Recent" ? "none" : "flex",
              }}
            />
            <Box
              sx={{
                px: 2,
                width: { md: "360px", xs: "100%" },
                mt: 2,
                display: sort === "Most Recent" ? "none" : "",
              }}
            >
              <Typography variant="h6">Ordered by</Typography>

              <FormControl
                sx={{
                  width: "100%",
                }}
              >
                <RadioGroup>
                  {ORDERED.map((item, index) => {
                    return (
                      <Box key={index}>
                        <FormControlLabel
                          value={item}
                          checked={item === ordered}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row-reverse",
                            width: "100%",
                            m: "0px !important",
                            py: "11px",
                          }}
                          control={
                            <Radio
                              sx={{
                                p: "0px !important",
                                color: palette.neutral[400],
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="subtitle2"
                              sx={{
                                color: palette.neutral[700],
                              }}
                            >
                              {item}
                            </Typography>
                          }
                          onClick={(e) => {
                            setOrdered(
                              (
                                e.currentTarget.children[0]
                                  .children[0] as HTMLInputElement
                              ).value
                            );
                          }}
                        />
                        {index + 1 === ORDERED.length ? null : (
                          <Divider
                            sx={{
                              display: index === 2 ? "none" : "flex",
                              borderColor: palette.neutral[100],
                            }}
                          />
                        )}
                      </Box>
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", p: 2 }}
            >
              <CustomButton type="fade" text="Cancel" onClick={handleClose} />
              <CustomButton
                type="primary"
                text="Apply"
                onClick={handleSubmit}
              />
            </Box>
          </Box>
        </Slide>
      </Modal>
    </Box>
  );
};
