import React from "react";
import { Box, Typography, CircularProgress, useTheme } from "@mui/material";

export const CircularProgressWithBackground = ({ value }: any) => {
  const { palette } = useTheme();

  return (
    <Box
      position="relative"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
    >
      {/* دایره پس‌زمینه */}
      <CircularProgress
        variant="determinate"
        value={100}
        size={40}
        thickness={4}
        style={{ color: palette.neutral[200], position: "absolute" }}
      />
      {/* دایره اصلی */}
      <CircularProgress
        variant="determinate"
        value={value}
        size={40}
        thickness={4}
        style={{ color: palette.neutral.min }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          sx={{
            fontSize: "13px",
            mt: 0.1,
          }}
          variant="subtitle2"
          component="div"
          color={palette.neutral.min}
        >
          {`${Math.round(value)}`}
        </Typography>
        <Typography
          sx={{
            fontSize: "9px",
            mt: -0.7,
          }}
          variant="subtitle2"
          component="div"
          color={palette.neutral.min}
        >
          %
        </Typography>
      </Box>
    </Box>
  );
};
