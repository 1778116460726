import { Badge, Button, Popover, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useRef, useState } from "react";
import { Icon } from "components/Icon";
import NotificationCard from "components/NotificationCard";
import {
  dismissAllNotification,
  fetchNotifications,
  fetchUnreadNotificationsCount,
} from "api/exploreJob";
import { useHistory } from "react-router-dom";
import { PageMetaType } from "pages/exploreJob/type";
import { toast } from "react-hot-toast";
import AlertNotif from "components/AlertNotif";
import { useSelector } from "react-redux";
import useNotificationsCountStore from "store/zustand/notificationsCount";

const NotificationPopup = (): JSX.Element => {
  const { palette } = useTheme();
  const setNotificationCount: any = useNotificationsCountStore(
    (state) => state.setNotificationUnread
  );
  const [notificationEl, setNotificationEl] =
    useState<HTMLButtonElement | null>(null);
  const notificationOpen = Boolean(notificationEl);
  const notificationId = notificationOpen ? "simple-popover" : undefined;
  const [Notification, setNotification] = useState<any>([]);
  const [NotificationUnreade, setNotificationUnread] = useState<any>();
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const [Page, setPage] = useState<number>(1);
  const [PageMeta, setPageMeta] = useState<PageMetaType>();
  const [Scrolled, setScrolled] = useState(false);
  const notifListRef = useRef<HTMLElement>();
  const history = useHistory();
  const { mode } = useSelector(
    (state: { mode: { mode: string } }) => state.mode
  );

  const handleNotificationClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setNotificationEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationEl(null);
  };
  const handleCloseClick = () => {
    setNotificationEl(null);
  };

  useEffect(() => {
    setShowSkeleton(true);
    fetchNotifications(1)
      .then((res) => {
        setPageMeta({
          pagesCount: res.meta.last_page,
          from: res.meta.from,
          to: res.meta.to,
          total: res.meta.total,
        });
        setNotification(res.data);
      })
      .finally(() => setShowSkeleton(false));
  }, []);

  useEffect(() => {
    fetchUnreadNotificationsCount().then((res: any) =>
      setNotificationUnread(res.data)
    );
  }, []);

  const markAllRead = () => {
    dismissAllNotification()
      .then((res) => {
        setNotificationCount("unread_count", res.data.unread_count);
        toast.custom((t) => (
          <AlertNotif title={res?.message} type="primary" target={t} />
        ));
        fetchNotifications(1).then((res) => {
          setPageMeta({
            pagesCount: res.meta.last_page,
            from: res.meta.from,
            to: res.meta.to,
            total: res.meta.total,
          });
          setNotification(res.data);
        });
      })
      .catch((err) =>
        toast.custom((t) => (
          <AlertNotif
            title={err?.response.data.error.message}
            type="success"
            target={t}
          />
        ))
      );
  };

  const handleScroll = useCallback(
    (el: any) => {
      if (Page < (PageMeta?.pagesCount ?? 0))
        if (
          el.target.scrollTop + el.target.clientHeight >=
          el.target.scrollHeight
        )
          setPage(Page + 1);

      if (document.body.offsetWidth < 860)
        setScrolled(el.target.scrollTop > 120);
      else setScrolled(false);
    },
    [Page, PageMeta, setPage]
  );
  useEffect(() => {
    let jobList = notifListRef.current;
    if (jobList) {
      jobList.addEventListener("scroll", handleScroll, false);
    }
    return () => {
      if (jobList) jobList.removeEventListener("scroll", handleScroll, false);
    };
    // eslint-disable-next-line
  }, [notifListRef, Page, PageMeta]);

  return (
    <>
      <Box
        sx={{
          ".css-leym9m-MuiButtonBase-root-MuiIconButton-root": {
            p: "8px !important",
          },
        }}
      >
        <span
          onClick={handleNotificationClick}
          aria-describedby={notificationId}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              px: "6.5px",
              "&:hover": { cursor: "pointer" },
              ml: 2,
            }}
          >
            <Icon iconSet="DefaultNotif" sx={{ color: palette.neutral[500] }} />
            <Typography
              variant="label"
              sx={{
                color: palette.neutral[500],
              }}
            >
              Activity
            </Typography>
          </Box>
        </span>
      </Box>
      <Popover
        id={notificationId}
        open={notificationOpen}
        anchorEl={notificationEl}
        onClose={handleNotificationClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          ".MuiPopover-paper	": {
            borderRadius: "16px",
            // scrollbarWidth: "0",
            // "&::-webkit-scrollbar": {
            //   width: "0px",
            // },
            minHeight: "428px",
          },
        }}
      >
        <Box
          sx={{
            width: "397px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: "8px",
              py: 0.5,
              position: "sticky",
              top: 0,
              borderBottom: `1px solid ${palette.neutral[100]}`,
              bgcolor: "white",
              zIndex: 100,
            }}
          >
            <Button
              sx={{
                display: "flex",
                p: 2,
                px: "8px !important",
                height: { md: 35, xs: 40 },
              }}
              disabled={Notification?.length > 0 ? false : true}
              onClick={markAllRead}
            >
              <Icon
                iconSet="Clear-all"
                sx={{
                  color:
                    Notification?.length > 0
                      ? palette.primary.main
                      : palette.neutral[400],
                }}
              />
              <Typography
                component="span"
                variant="button"
                sx={{
                  color:
                    Notification?.length > 0
                      ? palette.primary.main
                      : palette.neutral[400],
                  ml: 0.5,
                }}
              >
                Mark all as read
              </Typography>
            </Button>
            <Box sx={{ display: "flex" }}>
              <Box
                onClick={() => {
                  if (mode === "poster") {
                    history.push("/profile-poster?notification");
                    setNotificationEl(null);
                  } else {
                    history.push("/profile-tasker?notification");
                    setNotificationEl(null);
                  }
                }}
                sx={{
                  color: palette.neutral[500],
                  "&:hover": { backgroundColor: "unset", cursor: "pointer" },
                  p: "8px !important",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon
                  iconSet="Settings"
                  sx={{ color: palette.primary.main, fontSize: "20px" }}
                />
              </Box>
              <Box
                sx={{
                  color: palette.neutral[400],
                  "&:hover": { backgroundColor: "unset", cursor: "pointer" },
                  p: "8px !important",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleNotificationClose}
              >
                <Icon
                  iconSet="Close"
                  sx={{ color: palette.neutral[300], fontSize: "20px" }}
                  onClick={handleCloseClick}
                />
              </Box>
            </Box>
          </Box>
          {/* <NotificationItemSkeleton /> */}
          {Notification.length > 0 ? (
            <Box ref={notifListRef}>
              {Notification.map((item: any, index: number) => {
                return (
                  <NotificationCard
                    notif={item}
                    setNotification={setNotification}
                    setNotificationEl={setNotificationEl}
                  />
                );
              })}
            </Box>
          ) : (
            <Box
              sx={{
                py: 3,
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                mt: 1,
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  minWidth: "80px",
                  maxWidth: "80px",
                  height: "80px",
                  bgcolor: palette.primary[50],
                  borderRadius: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  outline: "8px solid #EBECFF80",
                  mb: 2,
                }}
              >
                <Icon
                  iconSet="NotifOff"
                  sx={{ fontSize: "48px", color: palette.primary.main }}
                />
              </Box>
              <Box sx={{ justifyContent: "center", display: "flex" }}>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: palette.neutral[500] }}
                >
                  You have no notifications
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default NotificationPopup;
